<template>
    <div class="container">
        <!-- <img style="width: 400px" src="../assets/img/404.png"> -->
        <h2><strong>
            Download Chachi and use offline!
        </strong></h2>
        <h4 class='pb-4 pt-2'>
            Click on <i class="fas fa-ellipsis-v"></i> and select "Add to Home Screen"
        </h4>
            <router-link to="/">
                <button class='btn btn-rectangle btn-rectangle-yellow'>Back</button>
            </router-link>

    </div>
</template>


<script>
export default {
	name: 'Install',
}
</script>

<style scoped>
a:hover {
    text-decoration: none;
}
button {
    width: 150px;
}
</style>
